<template>
  <div>
    <Navbar />
    <div class="topo produtos">
      <div class="container">
        <h1>Produtos</h1>
      </div>
    </div>
    <div class="conteudo conteudo-produtos">
      <div class="container">
        <div class="row">
          <div class="col-8 col-md-6">
            <h2 style="margin-top: 0">Produtos</h2>
            <h1>Goop Apresenta</h1>
          </div>
          <div class="col-4 col-md-6 text-right">
            <img
              src="../assets/imgs/home/takao-goop.png"
              alt="Takao Goop"
              class="img-fluid"
              width="204"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xs-12 col-md-8 componente">
            <img src="../assets/imgs/home/goop-apresenta.jpg" class="img-fluid" />
          </div>
        </div>
        <div class="row">
          <div class="col col-md-12">
            <h3>Conheça nossos produtos nacionais e importados.</h3>
          </div>
        </div>
        <div class="row carousel">
          <div class="col-xs-12 col-md-12">
            <VueSlickCarousel v-bind="settings">
              <div class="">
                <img
                  src="../assets/imgs/produtos/VALVULAS.png"
                  class="img-fluid mx-auto vertical-align"
                  draggable="false"
                />
              </div>
              <div class="">
                <img
                  src="../assets/imgs/produtos/PISTOES.png"
                  class="img-fluid mx-auto vertical-align"
                  draggable="false"
                />
              </div>
              <div class="">
                <img
                  src="../assets/imgs/produtos/TENSOR.png"
                  class="img-fluid mx-auto vertical-align"
                  draggable="false"
                />
              </div>
              <div class="">
                <img
                  src="../assets/imgs/produtos/TUCHOS.png"
                  class="img-fluid mx-auto vertical-align"
                  draggable="false"
                />
              </div>
              <div class="">
                <img
                  src="../assets/imgs/produtos/RETENTORES.png"
                  class="img-fluid mx-auto vertical-align"
                  draggable="false"
                />
              </div>
              <div class="">
                <img
                  src="../assets/imgs/produtos/BOMBA-DAGUA.png"
                  class="img-fluid mx-auto vertical-align"
                  draggable="false"
                />
              </div>
            </VueSlickCarousel>
            <a
              target="_blank"
              href="https://produtos.takao.com.br/"
              class="btn btn-azul"
              >Ver todos os produtos</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- CAROUSEL -->

    <div class="prod-section prod-section-01">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-7">
            <h1>Blog Takao</h1>
            <p>
              Conheça as novidades do ramo automotivo, encontre dicas exclusivas
              de manutenção e fique por dentro do melhor no mercado!
            </p>
            <a
              href="https://blog.takao.com.br/"
              target="_blank"
              class="btn btn-azul"
              >Saiba mais</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="prod-section prod-section-02">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-7">
            <h1>Youtube</h1>
            <p>Inscreva-se em nosso em canal no Youtube</p>
            <a
              href="https://www.youtube.com/channel/UCeN77ug1kQjXswHY7bwCv-g"
              target="_blank"
              class="btn btn-azul"
              >Saiba mais</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="prod-section prod-section-03">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-7">
            <h1>Academia Motor</h1>
            <p>
              <span>CHEGOU A NOVA ACADEMIA DO MOTOR!</span><br />Conheça a maior
              plataforma de ensino à distância para mecânicos do Brasil.
            </p>
            <a
              href="https://academia.takao.com.br/"
              target="_blank"
              class="btn btn-azul"
              >Saiba mais</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <Footer />
  </div>
</template>
<style>

@media (min-width: 600px)
{
  .slick-prev:before,
    .slick-next:before {
    color: #034ea2 !important;
    font-size: 40px;
    }
     .slick-next, .slick-prev {
    width: 40px;
    height: 40px;

    }
    .slick-next{
    position: absolute;
    right: -50px;
    }
    .slick-prev{
    position: absolute;
    left: -50px;
    }
}
    

</style>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "Produtos",
  components: {
    Navbar,
    Footer,
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        slidesToShow: 5,
        //"slidesToScroll": 1,
        initialSlide: 0,
        touchMove: true,
        swipe: true,
        swipeToSlide: true,
        centerMode: true,
        draggable: true,
        cssEase: "ease",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: false,
              touchMove: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
            arrow: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: false,
              touchMove: true,
            },
          },
        ],
      },
    };
  },
};
</script>
